import { useStaticQuery, graphql } from "gatsby"

const useJobs = language => {
  
  const data = useStaticQuery(graphql`
    query Languages {
      jp: allStrapiJobs(
        filter: { Archived: { eq: false }, Language: { eq: "Japanese" } }
        sort: { fields: DatePublication, order: DESC }
      ) {
        nodes {
          JobDescription
          Allowances
          HolidayVacation
          CompanyProfile
          Insurance
          Manualid
          Salary
          SalaryIncrease
          Title
          WorkingTime
          Archived
          published_at(formatString: "YYYY年 MM月 DD日")
          strapiId
          MandatoryRequirement
          WelcomeRequirement
          NewGraduates {
            Imposible
            Negotiable
            Posible
          }
          VisaSupport {
            None
            ThereRegulation
            Yes
          }
          JobCategorie
          LocationDescription
          DatePublication
          locations {
            name
            # location_groups {
            #   SubLocation
            # }
          }
          marks {
            name
          }
          types {
            name
            image {
              publicURL
            }
          }
        }
      }
      en: allStrapiJobs(
        filter: { Archived: { eq: false }, Language: { eq: "English" } }
        sort: { fields: DatePublication, order: DESC }
      ) {
        nodes {
          JobDescription
          Allowances
          HolidayVacation
          CompanyProfile
          Insurance
          Manualid
          Salary
          SalaryIncrease
          Title
          WorkingTime
          Archived
          published_at(formatString: "YYYY年 MM月 DD日")
          strapiId
          MandatoryRequirement
          WelcomeRequirement
          NewGraduates {
            Imposible
            Negotiable
            Posible
          }
          VisaSupport {
            None
            ThereRegulation
            Yes
          }
          JobCategorie
          LocationDescription
          DatePublication
          locations {
            name
            # location_groups {
            #   SubLocation
            # }
          }
          marks {
            name
          }
          types {
            name
            image {
              publicURL
            }
          }
        }
      }
    }
  `)

  return language === "Japanese"
    ? data.jp.nodes
    : data.en.nodes.map(job => ({
        JobDescription: job.JobDescription,
        Allowances: job.Allowances,
        HolidayVacation: job.HolidayVacation,
        CompanyProfile: job.CompanyProfile,
        Insurance: job.Insurance,
        Manualid: job.Manualid,
        Salary: job.Salary,
        SalaryIncrease: job.SalaryIncrease,
        Title: job.Title,
        WorkingTime: job.WorkingTime,
        published_at: job.published_at,
        MandatoryRequirement: job.MandatoryRequirement,
        WelcomeRequirement: job.WelcomeRequirement,
        NewGraduates: job.NewGraduates,
        VisaSupport: job.VisaSupport,
        JobCategorie: job.JobCategorie,
        LocationDescription: job.LocationDescription,
        Archived: job.Archived,
        DatePublication: job.DatePublication,
        marks: job.marks,
        locations: job.locations,
        types: job.types,
        strapiId: job.strapiId,
      }))
}

export default useJobs
