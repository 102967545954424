import React from "react"
import { Link } from "gatsby"
import { FaAngleRight } from "react-icons/fa"
import { format } from "date-fns"

const JobContent = props => {
  const jobs = props  
  const type = props.typeJob
  const actualDate = format(new Date(), "yyyy-MM-dd")
  const jobDate = jobs.jobs.DatePublication
  const actualDate2 = actualDate.split("-")
  const jobDate2 = jobDate.split("-")
  const sub1 = actualDate2[0] * 365 + actualDate2[1] * 30 + actualDate2[2]
  const sub2 = jobDate2[0] * 365 + jobDate2[1] * 30 + jobDate2[2]
  const rest = sub1 - sub2
  const handleAddBrTag = string => {
    if (string === null) {
      return ""
    }
    return string.replace(/(\r\n|\n)/gm, "<br />")
  }

  const noImage = require("../images/icons/no-imagen.svg")
  return (
      <div className="container-full jobs-listing__job-info--box-job">
        <Link
          className="u-display-text-head"
          to={`/jobs-listing/${jobs.jobs.Manualid}`}
        >
          {jobs.jobs.Title}
        </Link>
        <div className="row">
          <div className="jobs-listing__job-info--image col-3 col-4 col-sm-3">
            <div className="box-nro-item">
              {jobs.jobs.types[0] ? (
                // src={jobs.jobs.types[0].image jobs.jobs.types[0].image.url}
                <>
                  {jobs.jobs.types[0].__typename ? (
                    <img
                      src={jobs.jobs.types[0].image.url}
                      alt={jobs.jobs.types[0].name}
                    />
                  ) : (
                    <img
                      src={jobs.jobs.types[0].image.publicURL}
                      alt={jobs.jobs.types[0].name}
                    />
                  )}
                </>
              ) : (
                <img className="no-imagen" src={noImage} alt="" />
              )}
              <span>No. {jobs.jobs.Manualid}</span>
            </div>
          </div>
          <div className="col-8 col-sm-9 jobs-listing__job-info--tags">
            {type === "osusume" ? (
              <p className="u-display-text">
                <Link to={`/jobs-listing/${jobs.jobs.Manualid}`}>
                  {jobs.jobs.Title}
                </Link>
              </p>
            ) : (
              <h3 className="u-display-text">
                <Link to={`/jobs-listing/${jobs.jobs.Manualid}`}>
                  {jobs.jobs.Title}
                </Link>
              </h3>
            )}

            <ul>
              {rest <= 7 && <li className="new-tag">NEW</li>}
              {jobs.jobs.marks ? (
                <>
                  {jobs.jobs.marks.map((marker, i) => (
                    <li key={i}>
                      <Link to={`/job-search/${marker.name}`}>{marker.name}</Link>
                    </li>
                  ))}
                </>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-4 col-sm-3 jobs-listing__job-info--title">
            <span>職種</span>
          </div>
          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
            <span>
              <Link to={`/jobs-listing/${jobs.jobs.JobCategorie}`}>
                {jobs.jobs.JobCategorie}
              </Link>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-3 col-4 col-sm-3 jobs-listing__job-info--title">
            <span>勤務地</span>
          </div>
          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
            {jobs.jobs.locations ? (
              <>
                {jobs.jobs.locations.map((location, i) => (
                  <>
                    {location.name !== "その他" && location.name !== "日本" ? (
                      <>
                        {i === 0 ? (
                          <span key={i} style={{ marginRight: "5px" }}>メキシコ / </span>
                        ) : (
                          <span></span>
                        )}

                        <span key={i} style={{ marginRight: "5px" }}>
                          <Link to={`/jobs-location/${location.name}`}>
                            {location.name}
                          </Link>
                        </span>
                      </>
                    ) : (
                      <>
                        <span key={i} style={{ marginRight: "5px" }}>
                          <Link to={`/jobs-location/${location.name}`}>
                            {location.name}
                          </Link>
                        </span>
                      </>
                    )}
                  </>
                ))}
              </>
            ) : (
              ""
            )}
            <span>{jobs.jobs.LocationDescription}</span>
          </div>
        </div>

        <div className="row">
          <div className="col-3 col-4 col-sm-3 jobs-listing__job-info--title">
            <span>給与(額面)</span>
          </div>
          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
            <span dangerouslySetInnerHTML={{ __html: jobs.jobs.Salary }}></span>
          </div>
        </div>
        {jobs.jobs.MandatoryRequirement ? (
          <div className="row">
            <div className="col-3 col-4 col-sm-3 jobs-listing__job-info--title">
              <span> 必須要件</span>
            </div>
            <div className="col-8 col-sm-9 jobs-listing__job-info--description">
              <span
                dangerouslySetInnerHTML={{
                  __html: handleAddBrTag(jobs.jobs.MandatoryRequirement),
                }}
              ></span>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-3 col-4 col-sm-3 jobs-listing__job-info--title">
            <span>会社概要</span>
          </div>
          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
            <span>{jobs.jobs.CompanyProfile}</span>
          </div>
        </div>
        {/* TO DO : add NEW tag with a component in strapi with range date */}
        <div className="row jobs-listing__job-info--buttons">
          <a
            target="_blank"
            rel="noreferrer"
            href={`/jobs-listing/${jobs.jobs.Manualid}`}
            className="btn-info-job"
          >
            <FaAngleRight /> 詳細を確認
          </a>
          <div className="jobs-listing__job-info--box-collapse">
            <Link to="/job-form" state={{workid: jobs.jobs.Manualid}}  className="btn-info-job btn-hover-collapse">
              <FaAngleRight />
              今すぐ無料相談
            </Link>
          </div>
        </div>
      </div>
  )
}

export default JobContent
