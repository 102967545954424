import { useStaticQuery, graphql } from "gatsby"

const useBestJobs = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiBestJobs {
        nodes {
          Title
          TopJobs {
            Link
            Title
          }
        }
      }
    }
  `)
  return data.allStrapiBestJobs.nodes.map(category => ({
    title: category.Title,
    topjobs: category.TopJobs,    
  }))
}

export default useBestJobs
